import { UploadFile } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { useState } from 'react';
import { useGlobalContext } from '../../../context/GlobalContext';
import {
  IAcaArquivoDTO,
  IRelatorioAulaFullDTO
} from '../../../models/happy-code-api.model';
import { useRelatorioAulaService } from '../../../services/aca-relatorio-aula.service';
import { useNotificationService } from '../../../services/notification.service';
import { useNavigate } from 'react-router-dom';

export const useCreateRelatorioAula = () => {
  const { setIsGlobalLoading } = useGlobalContext();
  const [relatorioAula, setRelatorioAula] = useState<IRelatorioAulaFullDTO>();
  const notification = useNotificationService();
  const relatorioAulaService = useRelatorioAulaService();
  const navigate = useNavigate();
  

  const onSubmit = (relatorioAula: IRelatorioAulaFullDTO) => {    
    if (relatorioAula.arquivos.length === 0) {
     notification({
        description: `Por favor, insira ao menos um arquivo!`,
        type: 'error',
        message: 'Erro!',
      });
      return
    }
    setIsGlobalLoading(true);
    relatorioAulaService.createFull(relatorioAula).then(() => {
      notification({
        description: `Relatório salvo com sucesso!`,
        type: 'success',
        message: 'Sucesso!',
      });
    }).finally(() => {
      setIsGlobalLoading(false);
      navigate('/minhas-aulas-happy');
    });
  };

  const onChange = ({ file }: UploadChangeParam) => {
    const { status } = file;

    const response = file.response as IAcaArquivoDTO;

    if (status === 'done') {
      notification({
        description: `${file.name} upload feito com sucesso!`,
        type: 'success',
        message: 'Sucesso!',
      });
      file.status = 'done';
      setRelatorioAula((prevState) => ({
        ...prevState,
        arquivos: [...(prevState.arquivos || []), { id: response.id, ...response }],
      }));
    } else if (status === 'error') {
      notification({
        description: `${file.name} falha ao fazer o upload do arquivo`,
        type: 'error',
        message: 'Erro!',
      });
    } else if (status === 'removed') {
    }
  };

  const beforeUpload = (file: UploadFile) => {
    const TWENTY_FIVE_MEGABYTES_IN_BYTES = 25 * 1024 * 1024;

    if (file.size > TWENTY_FIVE_MEGABYTES_IN_BYTES) {
      file.status = 'error';
      return notification({
        description: 'O tamanho do arquivo deve ser inferior a 25MB.',
        type: 'error',
        message: 'Erro!',
      });
    }

    if (file.status === 'error')
      return notification({
        description: `Erro ao enviar arquivo: ${file.error} `,
        type: 'error',
        message: 'Erro!',
      });
  };

  const onRemoveFile = (fileId: number) => {
    setRelatorioAula((prev) => ({
      ...prev,
      arquivos: prev.arquivos.filter((file) => file.id !== fileId),
    }))

    notification({
      description: `Arquivo removido com sucesso!`,
      type: 'success',
      message: 'Sucesso!',
    });
  }

  return {
    onSubmit,
    setRelatorioAula,
    onChange,
    onRemoveFile,
    beforeUpload,
    relatorioAula,
  };
};
