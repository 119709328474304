import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { IRelatorioAulaDTO } from "../../../models/happy-code-api.model";
import { useGlobalContext } from "../../../context/GlobalContext";
import { useRelatorioAulaService } from "../../../services/aca-relatorio-aula.service";
import { useNotificationService } from "../../../services/notification.service";
import { useState } from "react";

export default function useActionTable() {
  const navigate = useNavigate();
  const notification = useNotificationService();
  const { setIsGlobalLoading } = useGlobalContext();
  const relatorioAulaService = useRelatorioAulaService();

  const [trigger, setTrigger] = useState(0);

  const redirectToEditOrCreate = (id: number) => {
    if (id) return navigate(`/minhas-aulas-happy/create/${id}`);
    navigate('/minhas-aulas-happy/create');
  };

  const handleInactivateProduct = async (relatorio: IRelatorioAulaDTO) => {
    Modal.confirm({
      title: `Deseja realmente excluir o lançamento do relatório?`,
      content: relatorio?.titulo,
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Não',
      onOk: async () => {
        setIsGlobalLoading(true);
        try {
          await relatorioAulaService.inativateRelatorio(relatorio.id);
          notification({
            description: `Relatório excluído com sucesso!`,
            type: 'success',
            message: 'Sucesso!',
          });
          setTrigger((prev) => prev + 1);
        } catch (error) {
          notification({
            description: `Erro ao excluir o relatório.`,
            type: 'error',
            message: 'Erro!',
          });
        } finally {
          setIsGlobalLoading(false);
        }
      },
      onCancel: () => {},
    });
  };

  const actions = [
    {
      onClick: (relatorio: IRelatorioAulaDTO) => redirectToEditOrCreate(relatorio.id),
      label: "Visualizar lançamento",
    },
    {
      onClick: (relatorio: IRelatorioAulaDTO) => handleInactivateProduct(relatorio),
      label: "Excluir lançamento",
    },
  ];

  return {
    redirectToEditOrCreate,
    handleInactivateProduct,
    actions,
    trigger,
  };
}
