import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
  IMaterialCleanDTO,
  IMaterialDTO,
  IMaterialFullDTO,
  IRequestDTO,
  IUsuarioTrilhaCleanDTO,
} from '../models/happy-code-api.model';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useUsuarioTrilhaService = () => {
  const resourceURL = '/usuario-trilha';

  const finalizarProva = (body: IRequestDTO): Promise<AxiosResponse<IUsuarioTrilhaCleanDTO>> =>{
    return http.post(`${resourceURL}/finalizar-prova`, body);
  };

 const verificarUsuarioTrilha = (
   idUsuario: number,
  idTrilha: number
): Promise<AxiosResponse<IUsuarioTrilhaCleanDTO>> => {
  return http.get(`${resourceURL}/${idUsuario}/trilha/${idTrilha}/certificado`);
};

const dowloadCertificate = (
  idUsuario: number,
  idTrilha: number,
  cargaHoraria: string
) => {
  return http.get(`${resourceURL}/${idUsuario}/trilha/${idTrilha}/carga-horaria/${cargaHoraria}/certificado/download`,{
    responseType: 'blob'
  });
};

  return {
    ...baseFindById<IMaterialCleanDTO, IMaterialDTO, IMaterialFullDTO>(
      resourceURL,
    ),
    ...baseFindList<IMaterialCleanDTO, IMaterialDTO, IMaterialFullDTO>(
      resourceURL,
    ),
    ...baseFind<IMaterialCleanDTO, IMaterialDTO, IMaterialFullDTO>(resourceURL),
    finalizarProva,
    verificarUsuarioTrilha,
    dowloadCertificate
  };
};
