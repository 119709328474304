import { Button } from 'antd';
import { IAcaArquivoCleanDTO } from '../../../models/happy-code-api.model';
import '../../MaterialDeApoio/arquivo/components/ArquivoContainer/arquivos-all.styles.scss';
import * as Unicons from '@iconscout/react-unicons';
import { useAcaArquivoService } from '../../../services/aca-arquivo.service';
import { useGlobalContext } from '../../../context/GlobalContext';

interface BoxDowloadProps {
  arquivo: IAcaArquivoCleanDTO;
}

export default function BoxDowload({ arquivo }: BoxDowloadProps) {
  const arquivoService = useAcaArquivoService();
  const { setIsGlobalLoading } = useGlobalContext();
  const handleDowload = async () => {
    setIsGlobalLoading(true);
    try {
      const response = await arquivoService.download(
        arquivo.url,
        arquivo.codigo,
      );
      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      const tempLink = document.createElement('a');
      tempLink.href = window.URL.createObjectURL(blob);
      tempLink.setAttribute('download', arquivo.codigo);
      tempLink.click();
      window.URL.revokeObjectURL(tempLink.href);
    } catch (error) {
      console.error(error);
    } finally {
      setIsGlobalLoading(false);
    }
  };
  return (
    <div className="arquivo-item" style={{ width: '30%' }}>
      <Unicons.UilFileDownload size={80} />
      <div className="arquivo-title">
        <h1>{arquivo?.codigo}</h1>
      </div>
      <Button
        onClick={handleDowload}
        disabled={false}
        className="button-primary"
      >
        Download
      </Button>
    </div>
  );
}
