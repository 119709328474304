import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../../context/GlobalContext';
import { IRelatorioAulaDTO } from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { PageableResponse } from '../../../models/response.model';
import { useProdutoRelatorioAula } from '../../../services/aca-produto-relatorio-aula.service copy';
import { useRelatorioAulaService } from '../../../services/aca-relatorio-aula.service';

export default function useFetchRelatorioAula() {
  const { setIsGlobalLoading } = useGlobalContext();
  const relatorioAulaService = useRelatorioAulaService();
  const produtoRelatorioAulaService = useProdutoRelatorioAula();

  const [page, setPage] = useState(null);
  const [relatoriosAula, setRelatoriosAula] =
    useState<PageableResponse<IRelatorioAulaDTO>>();

  const addPredicatesOnQuery = () => {
    const predicate = new Predicate();

    predicate.removeAllOption();
    predicate.addOption('ativo', true);
    predicate.sort = 'asc'
    predicate.addSort("dataCriacao")
    predicate.size = 8;

    if (page) predicate.setPage(page);

    return predicate;
  };

  const fetchRelatoriosPagination = async () => {
    const predicate = addPredicatesOnQuery();
    setIsGlobalLoading(true);
    relatorioAulaService
      .find(predicate)
      .then(({ data }) => {
        setRelatoriosAula(data);
      })
      .finally(() => setIsGlobalLoading(false));
  };
  const onChangePage = (event, page) => {
    event.preventDefault();
    setPage(page);
  };

  useEffect(() => {
    fetchRelatoriosPagination();
  }, [page]);

  const fetchRelatoriosFullById = async (id: number) => {
    setIsGlobalLoading(true);
    return relatorioAulaService
      .findFullByIdCustom(id)
      .finally(() => setIsGlobalLoading(false));
  };

  const fetchProdutoRelatorioAula = async () => {
    setIsGlobalLoading(true);
    return produtoRelatorioAulaService
      .findCleanList()
      .finally(() => setIsGlobalLoading(false));
  };

  return {
    fetchRelatoriosPagination,
    fetchRelatoriosFullById,
    fetchProdutoRelatorioAula,
    relatoriosAula,
    onChangePage,
  };
}
