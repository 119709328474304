import { ChangeEvent, Fragment } from "react";
import GenericPageableTable from "../../../components/GenericPageableTable/generic-pageable-table.component";
import { IRelatorioAulaCleanDTO } from "../../../models/happy-code-api.model";
import { PageableResponse } from "../../../models/response.model";

interface IMinhasAulasTableProps {
  data: PageableResponse<IRelatorioAulaCleanDTO>,
  onPageChange: (e: ChangeEvent<unknown>, page: number) => void,
  columns: any,
  actions: Array<{  
    label: string;
    onClick: (item: any) => void;
  }>;
  
}

export default function MinhasAulasTable({data, onPageChange, columns, actions}: IMinhasAulasTableProps) {
  return (
    <Fragment>
       <GenericPageableTable
          data={data}
          columns={columns}
          onPageChange={onPageChange}
          rowActions={actions.map((action) => ({ label: action.label, onClick: action.onClick }))}    
          />
    </Fragment>
  )
}
