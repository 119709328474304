import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Tabs, TabsProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import '../../global.css';
import './turma-detalhe.css';
import AvaliacoesTable from './components/AvaliacoesTable';
import AlunoTable from './components/AlunoTable';
import AulasTable from './components/AulasTable';
import useTurmaDetalhe from './useTurmaDetalhe';
import BoxDataTurmaDetalhe from './components/BoxDataTurmaDetalhe';
import PorcentagemAulas from './components/PorcentagemAulas';
import SidebarAndContainer
  from '../../components/sidebar-and-container/sidebar-and-container.component';
import Title from '../../components/title/title.component';
import './turma-detalhe.styles.scss';
import { turmaIsEnglishStore } from './store/turma-is-english.store';


export const TurmasDetalhe: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { alunos, aulas, avaliacao } = useTurmaDetalhe();
  const { turmaIsEnglish } = turmaIsEnglishStore();

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Alunos',
      children: <AlunoTable
        alunosTableData={alunos.data}
        onPageChange={alunos.onPageChange}
      />
    },
    // {
    //   key: '2',
    //   label: 'Aulas',
    //   children: <AulasTable
    //     aulasTableData={aulas.data}
    //     onPageChange={aulas.onPageChange}
    //     page={aulas.page}
    //   />
    // },
    ...(turmaIsEnglish
      ? [
        {
          key: '3',
          label: 'Avaliação',
          children: (
            <AvaliacoesTable
              tableData={avaliacao.data}
              onPageChange={avaliacao.onPageChange}
              findAvaliacoes={avaliacao.function}
            />
          )
        }
      ]
      : [])
  ];

  return (
    <SidebarAndContainer gestaoSalaAula={true}>
      <div className="turma-detalhe__head-buttons">
        <Button
          onClick={() => navigate('/turmas')}
          className="button-second"
          icon={<ArrowLeftOutlined />}
        >
          Voltar
        </Button>
        <Title title={'Detalhes da turma'} />
      </div>

      <div className="box-data-turma-detalhe-porcentagem-aulas-wrapper">
        <BoxDataTurmaDetalhe />
        <PorcentagemAulas />
      </div>

      <Tabs defaultActiveKey="1" items={items} />
    </SidebarAndContainer>
  );
};
