import { useParams } from 'react-router-dom';
import { useAcaCursoService } from '../../../../services/aca-curso.service';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { PageableResponse } from '../../../../models/response.model';
import { IAcaCursoDTO } from '../../../../models/happy-code-api.model';
import { Predicate } from '../../../../models/predicate.model';
import { Operators } from '../../../../enum/operators.enum';
import useFindStatusTrilhaById from '../../cursos-aulas/hooks/use-find-status-trilha-by-id';
import { useGlobalContext } from '../../../../context/GlobalContext';

export default function useFindCursoData() {
  const { idTrilha } = useParams();
  const { findCustom, findList } = useAcaCursoService();
  const { usuario } = useGlobalContext();

  const [search, setSearch] = useState('');
  const [hasAvaliacao, setHasAvaliacao] = useState(false);
  const [cursoData, setCursoData] = useState<PageableResponse<IAcaCursoDTO>>();
  const [cursoListData, setCursoListData] = useState<IAcaCursoDTO[]>();
  const { findTrilhaProgress } = useFindStatusTrilhaById();

  const buildPredicate = useCallback(
    (page = 0) => {
      const predicate = new Predicate();

      predicate.removeAllOption();

      predicate.size = 8;
      predicate.addOption('trilha.id', idTrilha);
      predicate.addOption('ativo', true);
      predicate.setPage(page);
      if (search)
        predicate.addOption('nome', search.trim(), Operators.CONTAINS);

      return predicate;
    },
    [idTrilha, search],
  );

  const findCurso = useCallback(
    async (page = 0): Promise<void> => {
      const predicate = buildPredicate(page);

      await findCustom(predicate)
        .then(({ data }) => setCursoData(data))
        .catch((error) => console.error(error));
    },
    [buildPredicate],
  );

  const findCursoList = useCallback(async () => {
    const predicate = new Predicate();
    predicate.removeAllOption();
    predicate.addOption('trilha.id', idTrilha);
    predicate.addOption('ativo', true);
    await findList(predicate)
      .then(({ data }) => setCursoListData(data))
      .catch((error) => console.error(error));
  }, [findList, idTrilha]);

  const onPageChange = (event: ChangeEvent<unknown>, page: number) => {
    event.preventDefault();
    findCurso(page);
  };

  useEffect(() => {
    if (usuario && usuario.id) {
      (async () => {
        const data = await findTrilhaProgress(usuario);
        setHasAvaliacao(data);
      })();
    }
    if (idTrilha) {
      findCursoList();
    }
  }, [usuario, idTrilha]);

  return {
    cursoData,
    onPageChange,
    findCurso,
    search,
    setSearch,
    hasAvaliacao,
    cursoListData,
  };
}
