import {
  IProdutoRelatorioAulaCleanDTO,
  IProdutoRelatorioAulaDTO,
  IProdutoRelatorioAulaFullDTO
} from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useProdutoRelatorioAula = () => {
  const resourceURL = '/aca-produto-relatorio-aula';

  return {
        ...baseFind<IProdutoRelatorioAulaCleanDTO, IProdutoRelatorioAulaDTO, IProdutoRelatorioAulaFullDTO>(resourceURL),
        ...baseFindList<IProdutoRelatorioAulaCleanDTO, IProdutoRelatorioAulaDTO, IProdutoRelatorioAulaFullDTO>(resourceURL),
        ...baseFindById<IProdutoRelatorioAulaCleanDTO, IProdutoRelatorioAulaDTO, IProdutoRelatorioAulaFullDTO>(resourceURL),
        ...baseCreate<IProdutoRelatorioAulaCleanDTO, IProdutoRelatorioAulaDTO, IProdutoRelatorioAulaFullDTO>(resourceURL),
        ...baseUpdate<IProdutoRelatorioAulaCleanDTO, IProdutoRelatorioAulaDTO, IProdutoRelatorioAulaFullDTO>(resourceURL),}
};
