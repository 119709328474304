import { Button } from "antd";
import SidebarAndContainer from "../../components/sidebar-and-container/sidebar-and-container.component";
import UserWelcomeCard from "../../components/user-welcome-card/user-welcome-card.component";
import "./styles.scss";
import MinhasAulasTable from "./components/minhas-aulas-table";
import useActionTable from "./hooks/useActionTable";
import { useEffect } from "react";
import useFetchRelatorioAula from "./hooks/useFetchRelatorioAula";
import { MinhasAulasTableColumns } from "./hooks/minhas-aulas-table-columns";

export default function MinhasAulasHappy() {
  const {redirectToEditOrCreate, actions, trigger} = useActionTable();
  const {fetchRelatoriosPagination, relatoriosAula, onChangePage} = useFetchRelatorioAula();
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {fetchRelatoriosPagination()}, [trigger]);
  return (
    <SidebarAndContainer  minhasAulasHappy={true}>
      <UserWelcomeCard/>
      <div className="minhas-aulas-happy-container">
        <div className="minhas-aulas-happy-header">
          <h2>Envios de relatórios de aulas</h2>
          <Button className="button-primary" onClick={() => redirectToEditOrCreate(null)}>+ Novo envio</Button>
        </div>
        <div className="minhas-aulas-happy-table">
         <MinhasAulasTable
         data={relatoriosAula}
         onPageChange={onChangePage}
         columns={MinhasAulasTableColumns}
         actions={actions}
         />
        </div>
      </div>
    </SidebarAndContainer>
  )
}

