import { useEffect, useState } from 'react';
import useFindCursoData from './hooks/use-find-curso-data.hook';
import { useGlobalContext } from '../../../context/GlobalContext';
import useFindStatusTrilhaById from '../cursos-aulas/hooks/use-find-status-trilha-by-id';

export default function useTrilhaId() {
  const { setIsGlobalLoading, usuario } = useGlobalContext();
  const [hasAvaliacao, setHasAvaliacao] = useState(false);
  const { findTrilhaProgress } = useFindStatusTrilhaById();

  const [resultAccordionIsOpen, setResultAccordionIsOpen] = useState(true);

  const { cursoData, onPageChange, findCurso, setSearch, cursoListData } =
    useFindCursoData();

  useEffect(() => {
    const controller = new AbortController();

    const timeoutFunction = setTimeout(() => {
      setIsGlobalLoading(true);

      Promise.all([findCurso(0)]).finally(() => setIsGlobalLoading(false));
    }, 600);

    return () => {
      controller.abort();
      clearTimeout(timeoutFunction);
    };
  }, [findCurso, setIsGlobalLoading]);

  const trilha = cursoData?.content[0]?.trilha;

  const totalCoins = cursoData?.content
    ?.map((item) => item?.happyCoins)
    .reduce((sum: number, current: number) => sum + current, 0);

  const formattedHappyCoins = totalCoins?.toLocaleString('de-DE');

  const trilhaTitle = (): string => {
    if (!trilha) return '';

    return trilha?.nome1 + ' ' + trilha?.nome2;
  };

  const baseImageUrl =
    process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_CONTAINER_ACADEMY;

  const trilhaIcon = () => {
    if (!trilha) return;

    return trilha?.icone
      ? trilha?.icone
      : baseImageUrl + trilha?.arquivoIcone?.url;
  };

  const toggleResultAccordion = () =>
    setResultAccordionIsOpen(!resultAccordionIsOpen);

  useEffect(() => {
    if (usuario && usuario.id) {
      (async () => {
        const data = await findTrilhaProgress(usuario);
        setHasAvaliacao(data);
      })();
    }
  }, [usuario]);

  return {
    trilha,
    nome: trilhaTitle(),
    onPageChange,
    setSearch,
    formattedHappyCoins,
    cursoData,
    icon: trilhaIcon(),
    resultAccordionIsOpen,
    toggleResultAccordion,
    hasAvaliacao,
    cursoListData,
  };
}
