import SidebarAndContainer from '../../../components/sidebar-and-container/sidebar-and-container.component';

import * as Unicons from '@iconscout/react-unicons';
import { Pagination } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Title from '../../../components/title/title.component';
import { useGlobalContext } from '../../../context/GlobalContext';
import {
  IMaterialApoioDTO
} from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { PageableResponse } from '../../../models/response.model';
import { useMaterialApoioService } from '../../../services/materiais.apoio.service';
import { ArquivoContainer } from './components/ArquivoContainer';
import FiltrosArquivo from './components/ArquivoContainer/arquivosFiltros';

export default function ArquivosMaterialApoio() {
  const [data, setData] = useState<PageableResponse<IMaterialApoioDTO>>();
  const [predicate] = useState<Predicate>(new Predicate());
  const materialApoioService = useMaterialApoioService();
  const { setIsGlobalLoading } = useGlobalContext();
  const { idPasta } = useParams<any>();

  const findMaterialApoio = () => {
    setIsGlobalLoading(true);
    predicate.addOption('pasta.id', idPasta);
    predicate.addOption('ativo', true);
    predicate.size = 12;
    materialApoioService
      .find(predicate)
      .then((response) => {
        setData(response.data);
      })
      .finally(() => setIsGlobalLoading(false));
  };

  const onPageChange = (page: number) => {
    predicate.setPage(page - 1);
    findMaterialApoio();
  };

  useEffect(() => {
    findMaterialApoio();
  }, []);  

  return (
    <SidebarAndContainer materiaisApoio={true}>
      <Fragment>
        <div className="webinars-all-container__title-back-button">
          <Link to="/materiais-de-apoio" className="button-second">
            <Unicons.UilArrowLeft />
            Voltar
          </Link>

          <Title title="Arquivos" />
        </div>

        <div className="webinars-all-container__search-bar">
          <FiltrosArquivo findMaterialApoio={findMaterialApoio} predicate={predicate} />
        </div>

        <div className="webinars-all-container__webinars-cards">
          {data &&
            data.content.map((MaterialApoio) => <ArquivoContainer MaterialApoio={MaterialApoio} />)}
        </div>

        <div className="d-flex-row j-center-a-center w-100 pb-50">

          <Pagination
            onChange={(page) => onPageChange(page)}
            current={data ? data.pageable.pageNumber + 1 : 1}
            total={data ? data.totalElements : 0}
            pageSize={data ? data.pageable.pageSize : 12}
          />
        </div>
      </Fragment>
    </SidebarAndContainer>
  );
}
