import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../../context/GlobalContext';
import {
  IAcaAulaCleanDTO,
  IAcaCursoDTO,
} from '../../../../models/happy-code-api.model';
import { Predicate } from '../../../../models/predicate.model';
import { useAcaAulaService } from '../../../../services/aca-aula.service';
import { useUsuarioTrilhaService } from '../../../../services/usuario-trilha.service';

export default function useCertificate(trilhaCourses: IAcaCursoDTO[]) {
  const usuarioTrilhaService = useUsuarioTrilhaService();
  const { setIsGlobalLoading } = useGlobalContext();
  const [hasCertificado, setHasCertificado] = useState(false);
  const { usuario } = useGlobalContext();
  const { idTrilha } = useParams<any>();
  const aulaService = useAcaAulaService();
  const [classData, setClassData] = useState<IAcaAulaCleanDTO[]>([]);

  const verifyUserCertificate = async () => {
    if (!idTrilha || !usuario) return;
    setIsGlobalLoading(true);
    await usuarioTrilhaService
      .verificarUsuarioTrilha(
        parseInt(idTrilha),
        usuario?.id === null ? 0 : usuario.id,
      )
      .then(({ data }) => {
        setHasCertificado(data.aprovado);
      })
      .finally(() => setIsGlobalLoading(false));
  };

  const dowloadCertificate = async () => {
    const hours = calculateWorkload();

    setIsGlobalLoading(true);
    usuarioTrilhaService
      .dowloadCertificate(usuario.id, Number(idTrilha), hours)
      .then((response) => {
        const blob = new Blob([response.data], {
          type: 'application/x-pdf',
        });
        const tempLink = document.createElement('a');
        tempLink.href = window.URL.createObjectURL(blob);
        tempLink.download = 'certificado.pdf';
        tempLink.click();
        window.URL.revokeObjectURL(tempLink.href);
      })
      .finally(() => setIsGlobalLoading(false));
  };

  const findAcademyClass = useCallback(async () => {
    setIsGlobalLoading(true);

    try {
      const requests = trilhaCourses.map(async (curso) => {
        const predicate = new Predicate();
        predicate.addOption('ativo', true);
        predicate.addOption('curso.id', curso.id);

        const { data } = await aulaService.findClean(predicate);
        return data.content;
      });

      const results = await Promise.all(requests);
      const allClasses = results.flat();

      setClassData(allClasses);
    } finally {
      setIsGlobalLoading(false);
    }
  }, [aulaService, trilhaCourses]);

  useEffect(() => {
    verifyUserCertificate();
    if (trilhaCourses && trilhaCourses.length > 0) findAcademyClass();
  }, [usuario, idTrilha, trilhaCourses]);

  const calculateWorkload = () => {
    const schedules = classData.map(({ duracao }) => parseInt(duracao) || 0);

    const totalMinutes = schedules.reduce(
      (total, minutos) => total + minutos,
      0,
    );

    const hours = Math.floor(totalMinutes / 60);
    const minutesRemaining = totalMinutes % 60;

    const formattedHour = formatTime(hours);
    const formattedMinutes = formatTime(minutesRemaining);

    return `${formattedHour}:${formattedMinutes}`;
  };

  const formatTime = (hour: number): string => hour.toString().padStart(2, '0');

  return {
    hasCertificado,
    verifyUserCertificate,
    dowloadCertificate,
  };
}
