import { ColumnInterface } from "../../../interfaces/table/column.interface";
import { formattedDate } from "../../../util/format";


export interface IMinhasAulasHappyColumns {
  titulo: string
  dataCriacao: string
  produtoRelatorioAula: any
}

export const MinhasAulasTableColumns: ColumnInterface<IMinhasAulasHappyColumns>[] = [
  {
    key: "titulo",
    title: "Título",
    render: (item) => item.titulo
  },
  {
    key: "dataCriacao",
    title: "Data de envio",
    render: (item) => formattedDate(item.dataCriacao)
  },
  {
    key: "produto",
    title: "Produto",
    render: ({produtoRelatorioAula}) => produtoRelatorioAula?.descricao
  },
]

