import { UploadOutlined } from '@mui/icons-material';
import { Button, Form, Input, Radio, Upload } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SidebarAndContainer from '../../components/sidebar-and-container/sidebar-and-container.component';
import { IProdutoRelatorioAulaCleanDTO } from '../../models/happy-code-api.model';
import { token } from '../../util/store';
import { useCreateRelatorioAula } from './hooks/useCreateRelatorioAula';
import useFetchRelatorioAula from './hooks/useFetchRelatorioAula';
import BoxDowload from './components/box-dowload';
import { useGlobalContext } from '../../context/GlobalContext';
import { useUsuarioService } from '../../services/usuario.service';

export default function MinhasAulasHappyCreate() {
  const [produtoRelatorioAula, setProdutoRelatorioAula] =
    useState<IProdutoRelatorioAulaCleanDTO[]>();
  const [formDisable, setFormDisable] = useState(false);
  const {
    onSubmit,
    onChange,
    beforeUpload,
    setRelatorioAula,
    onRemoveFile,
    relatorioAula,
  } = useCreateRelatorioAula();
  const { fetchProdutoRelatorioAula, fetchRelatoriosFullById } =
    useFetchRelatorioAula();
  const navigate = useNavigate();
  const { idRelatorio } = useParams();
  const [form] = Form.useForm();
  const usuarioService = useUsuarioService();
  const {usuario} = useGlobalContext();

  useEffect(() => {
    fetchProdutoRelatorioAula().then(({ data }) =>
      setProdutoRelatorioAula(data),
    );
    if (idRelatorio) {
      fetchRelatoriosFullById(Number(idRelatorio)).then(({ data }) => {
        setRelatorioAula(data);
        form.setFieldsValue(data);
        setFormDisable(true);
      });
    }
  }, []);

  useEffect(() => {
    if (usuario) {
        usuarioService.findCleanById({id: usuario?.id}).then(({ data }) => {
          setRelatorioAula({
            ...relatorioAula,
            usuario: data
          })
        })
      }
  }, [usuario]);

  return (
    <SidebarAndContainer minhasAulasHappy={true}>
      <div className="minhas-aulas-happy-create-container">
        <div className="minhas-aulas-happy-create-header">
          <h1>
            {formDisable
              ? 'Revise as informações do relatório'
              : 'Insira as informações para concluir seu envio do relatório'}
          </h1>
        </div>
        <div className="minhas-aulas-happy-create-form">
          <Form
            className="outlined-form"
            disabled={formDisable}
            form={form}
            layout="vertical"
            onFinish={() => onSubmit(relatorioAula)}
            requiredMark={false}
          >
            <Form.Item
              label="Título"
              name="titulo"
              rules={[
                { required: true, message: 'Por favor, insira o título!' },
              ]}
            >
              <Input
                maxLength={90}
                placeholder="Digite o título"
                value={relatorioAula?.titulo}
                onChange={(e) =>
                  setRelatorioAula({ ...relatorioAula, titulo: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item
              label="Descrição"
              name="descricao"
              rules={[
                { required: true, message: 'Por favor, insira a descrição!' },
              ]}
            >
              <TextArea
                placeholder="Descreva o material"
                value={relatorioAula?.descricao}
                onChange={(e) =>
                  setRelatorioAula({
                    ...relatorioAula,
                    descricao: e.target.value,
                  })
                }
              />
            </Form.Item>
            {produtoRelatorioAula && (
              <Form.Item
                label="Produto"
                name={['produtoRelatorioAula', 'id']}
                rules={[
                  {
                    required: true,
                    message: 'Por favor, selecione um produto!',
                  },
                ]}
              >
                <Radio.Group
                  onChange={(e) => {
                    const selectedProduto = produtoRelatorioAula.find(
                      (produto) => produto.id === e.target.value,
                    );
                    setRelatorioAula({
                      ...relatorioAula,
                      produtoRelatorioAula: selectedProduto,
                    });
                  }}
                >
                  {produtoRelatorioAula?.map((produtoRelatorioAula) => (
                    <Radio
                      key={produtoRelatorioAula.id}
                      value={produtoRelatorioAula.id}
                    >
                      {produtoRelatorioAula.descricao}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            )}
            <Form.Item
              label="Ano e Turma"
              name="anoTurma"
              rules={[
                {
                  required: true,
                  message: 'Por favor, insira o ano e a turma!',
                },
              ]}
            >
              <Input
                maxLength={50}
                placeholder="Digite o ano e a turma"
                onChange={(e) =>
                  setRelatorioAula({
                    ...relatorioAula,
                    anoTurma: e.target.value,
                  })
                }
              />
            </Form.Item>
            {!formDisable ? (
              <Form.Item
                className="upload-container"
                label="Upload de Arquivo"
                name={['arquivo', 'codigo']}
              >
                <Upload
                  name="file"
                  headers={{ Authorization: `Bearer ${token()}` }}
                  action={`${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}aca-arquivo/upload`}
                  onChange={onChange}
                  beforeUpload={beforeUpload}
                  onRemove={(file) => onRemoveFile(file.response.id)}
                >
                  <Button
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      marginBottom: '1rem',
                    }}
                  >
                    <UploadOutlined
                      style={{ fontSize: '20px', marginBottom: '4px' }}
                    />
                    <span>Clique para fazer upload do arquivo</span>
                  </Button>
                </Upload>
                <p className="upload-info">
                  Arquivos permitidos: PDF, WORD, PPT, PNG, JPG, MP4, ZIP.
                  Tamanho máximo permitido por envio: 25MB.
                </p>
              </Form.Item>
            ) : (
              <>
                <h1 className="title-archive">Arquivos</h1>
                <div className="box-archive">
                  {relatorioAula?.arquivos?.map((arquivo) => (
                    <BoxDowload arquivo={arquivo} />
                  ))}
                </div>
              </>
            )}
            <div className="form-buttons">
              <Button
                disabled={false}
                className="button-line"
                style={{ width: '300px' }}
                onClick={() => navigate(-1)}
              >
                Voltar
              </Button>
              <Button
                className="button-primary"
                style={{ width: '300px' }}
                htmlType="submit"
              >
                Salvar
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </SidebarAndContainer>
  );
}
